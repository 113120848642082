.forgot-container {
  background: #fff;
  width: 500px;
  height: 350px;
  padding: 16px;
  display: inline-block;
  margin: 8px 0;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 8px 2px rgba(0, 0, 0, 0.2), 20px 12px 20px 0 rgba(0, 0, 0, 0.19);
  line-height: 25px;
}

  
.forgotButton {
  width: 50%;
  background-color: #2196F3;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  top: 50%;
  left: 50%;
  position: absolute;
  display: inline-block;
  transform: translate(-50%, -50%);
  margin-top: 80px;
}

.forgotButton:hover {opacity: 0.8;}

h2 { text-align: center; }

p { padding:5px; text-align: center; }