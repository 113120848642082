

@media only screen and (max-width: 600px){
    .contact_title_name{
        font-family: 'Oswald'; 
        font-size:  6vw; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 400; 
        line-height: 30px;
        text-align: center;
        margin-top: 5px;
    }

    .contact-container{
        margin: auto;
        width: 90%;
        height: 169vw;
        padding: 50px;
        margin-top: 10%;
        margin-bottom: 5%;
        background: #c0c3c4;
        border-radius: 10px;
    }

    .upper_dot_line, .bottom_dot_line{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .bottom_dot_line{
        width: 70%;
        margin-left: 10%;
    }

    .form-control{
        width: 100%;
    }

    .contact-container label{
        font-size: 2vw;
    }

    .form-container{
        display: inline-block;
        width: 100%;
        padding: 20px;
    }

    .contact-container input, textarea{
        width: 100%;
        height: 10%;
        padding: 10px;
        resize: none;
        border: 4px solid #ddd;
        margin-top: 2%;
        margin-bottom: 4%;
    }

    .form-btn{
        width: 35%;
        max-width: 100%;    
        background-color: #333;
        border: none;
        padding: 15px 32px;
        float: none;
        display: inline-block;
        
        border-radius: 3px;
        cursor: pointer;
        margin: 30px 0 0 0;
        

        text-decoration: none;
        color: white;
        text-align: center;
        font-size: 16px;
    } 

    .personal-data{
        width: 110%;
        height: 180px;
        padding: 10px;
        margin:auto;
        color:#fff;
    }
    
    .personal-data h3{
        width: 45%;
        display: inline-block;
        padding: 10px;
        font-size: 2vw;
        font-family: sans-serif;
        color:black;
        margin-left: 120px;
    }

    .data-image{
        width: 5%;
        height: 5%;
        display: inline-block;
        margin-left: 50px;
    }

    .social-links{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
    }

    .social-links img{
        width: 20%;
        height: 20%;
        margin: 0 20px 0 45%;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {    

    .contact_title_name{
        font-family: 'Oswald'; 
        font-size:  6vw; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 400; 
        line-height: 30px;
        text-align: center;
        margin-top: 10px;
    }

    .contact-container{
        margin: auto;
        width: 90%;
        height: 140vw;
        padding: 50px;
        margin-top: 5%;
        margin-bottom: 5%;
        background: #c0c3c4;
        border-radius: 10px;
    }

    .upper_dot_line, .bottom_dot_line{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .bottom_dot_line{
        width: 70%;
        margin-left: 10%;
    }

    .form-control{
        width: 100%;
    }

    .contact-container label{
        font-size: 2vw;
    }

    .form-container{
        display: inline-block;
        width: 100%;
        padding: 20px;
    }

    .contact-container input, textarea{
        width: 100%;
        height: 10%;
        padding: 10px;
        resize: none;
        border: 4px solid #ddd;
        margin-top: 2%;
        margin-bottom: 4%;
    }

    .form-btn{
        width: 30%;
        max-width: 100%;    
        background-color: #333;
        border: none;
        padding: 15px 32px;
        float: none;
        display: inline-block;
        
        border-radius: 3px;
        cursor: pointer;
        margin: 30px 0 0 0;
        

        text-decoration: none;
        color: white;
        text-align: center;
        font-size: 16px;
    } 

    .personal-data{
        width: 110%;
        height: 180px;
        padding: 10px;
        margin:auto;
        color:#fff;
    }
    
    .personal-data h3{
        width: 45%;
        display: inline-block;
        padding: 10px;
        font-size: 2vw;
        font-family: sans-serif;
        color:black;
        margin-left: 120px;
    }

    .data-image{
        width: 5%;
        height: 5%;
        display: inline-block;
        margin-left: 50px;
    }

    .social-links{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 10%;
    }

    .social-links img{
        width: 20%;
        height: 20%;
        margin: 0 20px 0 45%;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .contact_title_name{
        font-family: 'Oswald';
        font-size: 3em;
    }

    .contact-container{
        margin:auto;
        width: 90%;
        height: 135vw;
        padding: 50px;
        margin-top: 5%;
        margin-bottom: 10%;
        background: #c0c3c4;
        border-radius: 10px;
    }

    .upper_dot_line{
        margin-top: 30px;
    }

    .bottom_dot_line{
        width: 70%;
        margin-left: 10%;
    }

    .form-control{
        width: 100%;
    }

    .contact-container label{
        font-size: 2vw;
    }

    .form-container{
        display: inline-block;
        width: 100%;
        padding: 20px;
    }

    .contact-container input, textarea{
        width: 100%;
        height: 10%;
        padding: 10px;
        border: 4px solid #ddd;
        margin-top: 2%;
        margin-bottom: 4%;
    }

    .form-btn{
        width: 30%;
        max-width: 100%;    
        background-color: #333;
        border:none;
        padding: 15px 32px;
        float: none;
        display: inline-block;
        
        border-radius: 3px;
        cursor: pointer;
        margin: 30px 0 0 0;
        

        text-decoration: none;
        color: white;
        text-align: center;
        font-size: 16px;
    } 

    .personal-data{
        width: 90%;
        height: 180px;
        padding: 10px;
        color:#fff;
        margin-top: 5%;
        margin-left: 10%;
    }
    
    .personal-data h3{
        width: 45%;
        display: inline-block;
        padding: 10px;
        font-size: 2vw;
        font-family: sans-serif;
        color:black;
        margin-left: 120px;
    }

    .data-image{
        width: 5%;
        height: 5%;
        display: inline-block;
        margin-left: 50px;
    }

    .social-links{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
        margin-bottom: 10%;
    }

    .social-links img{
        width: 20%;
        height: 20%;
        margin: 0 20px 0 45%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .contact-container{
        margin: auto;
        width: 90%;
        height: 125vw;
        padding: 50px;
        margin-top: 5%;
        margin-bottom: 5%;
        background: #c0c3c4;
        border-radius: 10px;
    }

    .upper_dot_line, .bottom_dot_line{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .bottom_dot_line{
        width: 75%;
        margin-left: 12%;
    }

    .form-control{
        width: 100%;
    }

    .contact-container label{
        font-size: 2vw;
    }

    .form-container{
        display: inline-block;
        width: 100%;
        padding: 20px;
    }

    .contact-container input, textarea{
        width: 100%;
        height: 10%;
        padding: 10px;
        resize: none;
        border: 4px solid #ddd;
        margin-top: 2%;
        margin-bottom: 4%;
    }

    .form-btn{
        width: 30%;
        max-width: 100%;    
        background-color: #333;
        border: none;
        padding: 15px 32px;
        float: none;
        display: inline-block;
        
        border-radius: 3px;
        cursor: pointer;
        margin: 30px 0 50px 0;
        

        text-decoration: none;
        color: white;
        text-align: center;
        font-size: 16px;
    } 

    .personal-data{
        width: 100%;
        height: 23vw;
        padding: 10px;
        margin: auto;
        color:#fff;
    }
    
    .personal-data h3{
        width: 45%;
        display: inline-block;
        padding: 10px;
        font-size: 2vw;
        font-family: sans-serif;
        color:black;
        margin-left: 15vw;
    }

    .data-image{
        width: 5%;
        height: 5%;
        display: inline-block;
        margin-top: 1vw;
        margin-left: 15vw;
    }

    .social-links{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 10%;
    }

    .social-links img{
        width: 20%;
        height: 20%;
        margin: 0 20px 0 45%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .contact_title_name{
        font-family: 'Oswald'; 
        font-size: 3vw; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 400; 
        line-height: 30px;
        text-align: center;
        margin-top: 10px;
    }
    
    .upper_dot_line, .bottom_dot_line{
        border-top: 1px dotted gray;
        width: 100%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .bottom_dot_line{
        width: 80%;
        margin: 0 0 0 5%;
    }
    
    .contact-container{
        margin: auto;
        width: 80%;
        height: 40vw;
        padding: 50px;
        margin-top: 3%;
        background: #c0c3c4;
        border-radius: 10px;
    }
    
    .form-control{
        width: 100%;
    }
    
    .contact-container label{
        font-size: 18px;
    }

    .form-container{
        display: inline-block;
        width: 50%;
        padding: 20px;
    }
    
    .contact-container label{
        display: block;
        margin-bottom: 5px;
    }
    
    .contact-container input{
        width: 100%;
        padding: 10px;
        border: 4px solid #ddd;
    }

    .contact-container input{
        height: 2.3vw;
    }
    
    .contact-container textarea{
        height: 10vw;
        resize: none;
        color: black;
    }
    
    .form-btn{
        width: 30%;
        max-width: 100%;    
        background-color: #333;
        border:none;
        padding: 15px 32px;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 3px;
        cursor: pointer;
        margin-top: 2%;
    }
    
    .form-btn:hover{
        background-color: #424242;
    }
    
    .personal-data{
        width: 40%;
        height: 12vw;
        padding: 20px;
        float:right;
        color:#fff;
        margin: 5% 0 0 0;
    }
    
    .data-image{
        width: 20px;
        height: 20px;
        display:inline-block;
        margin-top: 0vw;
        margin-left: 0vw;
    }
    
    .personal-data h3{
        display: inline-block;
        padding: 10px;
        font-size: 1vw;
        font-family: sans-serif;
        color:black;
        margin-left: 7vw;
    }
    
    .social-links{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 10%;
    }
    
    .social-links img{
        width: 20%;
        height: 20%;
        margin: 0 20px 0 45%;
    }
}
