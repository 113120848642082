.content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.content h1{
    font-size: 100px;
}

.content h1,h2,h3{
    line-height: 2;
}

@media screen and (max-width: 950px) {
    .content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .content h1{
        font-size: 60px;
    }

    .content h2,h3{
        font-size: 16px;
    }
    
    .content h1,h2,h3{
        line-height: 2;
    }
}

@media screen and (max-width: 500px) {
    .content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .content h1{
        font-size: 40px;
    }

    .content h2,h3{
        font-size: 10px;
    }
    
    .content h1,h2,h3{
        line-height: 2;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  
}