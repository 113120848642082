.navbar {
    background: linear-gradient(90deg, rgb(39, 39, 39) 0%, rgb(33, 33, 33) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 90%;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    /* grid-gap: 10px; */
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 20px;
  }

  .logo-nav-links {
    color: #fff;
    display: flex;
    align-items: top;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color:#fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }


  .checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .label {
    float:right;
    margin-left: 50px;
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.1);
  }
  
  .label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 22px;
    transform: translateX(1.1px);
    transition: transform 0.2s linear;
  }
  
  .checkbox:checked + .label .ball {
    transform: translateX(21px);
  }
  
  
  .fa-moon {
    color: #f1c40f;
  }
  
  .fa-sun {
    color: #f39c12;
  }  


  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .NavbarItems {
    position: relative;
  }

  .navbar{
    width:100%;
  }

  .label {
    float: right;
    margin-left: 10vw;
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.1);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92.6vh;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    position: fixed;
  }

  .logo-nav-links {
    color: #fff;
    text-align: center;
    align-items: top;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .logo-nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo{
    width: 200px;
    height: 10px;
    font-size: 4vw;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    transform: translate(-100, 60%);
  }

  .nav-links {
    align-items: center;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1200px) {

  .NavbarItems {
    position: relative;
  }

  .navbar{
    width:100%;
    position: fixed;
  }

  .label {
    float: right;
    margin-left: 10vw;
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.1);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92.6vh;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    position: fixed;
  }

  .logo-nav-links {
    color: #fff;
    text-align: center;
    align-items: top;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .logo-nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo{
    width: 200px;
    height: 10px;
    font-size: 1.4em;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    transform: translate(-100, 60%);
  }

  .nav-links {
    align-items: center;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .navbar-logo{
    width: 220px;
    font-size: 1.5em;
    margin-left: 0;
  }
  

}